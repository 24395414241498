import React from "react";

const Footer = () => {
  return (
    <footer class="bg-primary text-gray-300 pt-20 pb-10 mx:px-48 px-10">
      <div class="container mx-auto px-4">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div class="space-y-3">
            <h3 class="text-md font-bold">Company Name</h3>
            <div className="text-sm space-y-6">
              <p>
                1234 Street Name
                <br />
                City, State 56789
              </p>
              <p>Email: contact@company.com</p>
              <p>Phone: (123) 456-7890</p>
            </div>
          </div>

          <div>
            <h3 class="text-md font-bold mb-4">Quick Links</h3>
            <ul class="space-y-3 text-sm">
              <li>
                <a href="/" class="hover:text-gray-400">
                  About Us
                </a>
              </li>
              <li>
                <a href="/" class="hover:text-gray-400">
                  Services
                </a>
              </li>
              <li>
                <a href="/" class="hover:text-gray-400">
                  Blog
                </a>
              </li>
              <li>
                <a href="/" class="hover:text-gray-400">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="text-md font-bold mb-4">Follow Us</h3>
            <ul class="flex space-x-3">
              <li>
                <a href="/" class="hover:text-gray-400">
                  <svg
                    class="w-4 h-4"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22.675 0H1.325C.595 0 0 .595 0 1.326v21.348C0 23.406.595 24 1.325 24h11.495V14.709h-3.13v-3.622h3.13V8.413c0-3.1 1.892-4.79 4.659-4.79 1.324 0 2.461.099 2.792.142v3.24h-1.917c-1.505 0-1.795.717-1.795 1.766v2.31h3.589l-.467 3.621h-3.122V24h6.119C23.406 24 24 23.406 24 22.674V1.326C24 .595 23.406 0 22.675 0z" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="/" class="hover:text-gray-400">
                  <svg
                    class="w-4 h-4"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23.954 4.569c-.885.392-1.83.656-2.825.775 1.014-.608 1.794-1.569 2.163-2.723-.951.563-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-2.718 0-4.92 2.203-4.92 4.917 0 .385.045.76.127 1.118-4.087-.205-7.715-2.165-10.141-5.144-.424.729-.666 1.574-.666 2.476 0 1.71.87 3.217 2.188 4.099-.807-.026-1.566-.247-2.229-.616v.062c0 2.386 1.698 4.377 3.95 4.828-.413.111-.849.171-1.296.171-.317 0-.626-.031-.928-.088.626 1.956 2.445 3.379 4.6 3.419-1.685 1.32-3.809 2.105-6.102 2.105-.395 0-.785-.023-1.17-.068 2.179 1.397 4.768 2.211 7.548 2.211 9.055 0 14.01-7.504 14.01-14.009 0-.213-.005-.426-.014-.637.962-.694 1.797-1.562 2.457-2.549z" />
                  </svg>
                </a>
              </li>
              <li>
                <a href="/" class="hover:text-gray-400">
                  <svg
                    class="w-4 h-4"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163C6.477 2.163 2 6.64 2 12.163c0 4.511 3.183 8.261 7.388 9.604.54.097.74-.234.74-.523 0-.26-.01-.946-.014-1.857-3.004.65-3.63-1.448-3.63-1.448-.49-1.248-1.195-1.58-1.195-1.58-.977-.668.074-.654.074-.654 1.08.075 1.648 1.108 1.648 1.108.961 1.648 2.523 1.172 3.138.896.097-.696.376-1.172.684-1.442-2.399-.272-4.92-1.199-4.92-5.34 0-1.18.42-2.146 1.108-2.899-.112-.273-.479-1.37.104-2.855 0 0 .902-.288 2.955 1.105a10.336 10.336 0 0 1 5.381 0c2.054-1.393 2.954-1.105 2.954-1.105.584 1.485.217 2.582.106 2.855.691.753 1.108 1.719 1.108 2.899 0 4.152-2.526 5.064-4.93 5.332.388.334.732 1.007.732 2.03 0 1.464-.014 2.645-.014 3.005 0 .29.198.624.746.518 4.211-1.343 7.394-5.093 7.394-9.603C22 6.64 17.523 2.163 12 2.163z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-8 border-t border-gray-500 pt-4 text-center">
          <p className="text-sm">
            &copy; 2024 Company Name. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
